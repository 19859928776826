<template>
    <nav class="toolbar" :class="{ 'is-hidden': !toolbarIsShown }">
        <div class="left">
            <router-link :to="{ name: 'edit' }" class="has-icon">
                <i data-feather="edit-2" /> Edit projects
            </router-link>
            <router-link :to="{ name: 'add' }" class="has-icon">
                <i data-feather="plus-square" /> Add project
            </router-link>
        </div>
        <div class="right">
            <router-link :to="{ name: 'profile' }" class="has-icon">
                <i data-feather="user" /> {{ currentUser.full_name }}
            </router-link>
        </div>

        <div class="pull-tab-wrapper" @click="toolbarIsShown = !toolbarIsShown">
            <div class="pull-tab"></div>
        </div>
    </nav>
</template>

<script>
import feather from "feather-icons";
import { mapGetters } from "vuex";

export default {
    name: 'Toolbar',
    props: ['full_name'],
    data() {
        return {
            toolbarIsShown: true
        }
    },
    mounted() {
        feather.replace({ 
            'width': '14px', 
            'height': '14px'
        });
    },
    computed: {
        ...mapGetters([ 'isAuthenticated', 'currentUser' ])
    }
}
</script>

<style>

</style>