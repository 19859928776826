<template>
    <div class="overview">
        <Toolbar 
            v-if="isAuthenticated"
        />

        <!-- <OverviewHeader :full_name="username" 
        :bio="currentUser.bio" 
        :socials="currentUser.socials"
         /> -->
        <OverviewHeader :full_name="username" />


        <CardsGrid :projects="projects" />
    </div>
</template>

<script>
import Toolbar from "@/components/toolbar";
import OverviewHeader from "@/components/overview-header";
// import CardsFilter from "@/components/cards-filter";
import CardsGrid from "@/components/cards-grid";
import { mapGetters } from "vuex";
import ProjectService from "@/services/ProjectService"

export default {
    name: "overview",
    props: {
        username: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // socials: [
            //     {
            //         name: "Mail",
            //         url: "mailto:hello@linuscodes.com"
            //     },
            //     {
            //         name: "Instagram",
            //         url: "https://instagram.com/linuscodes"
            //     },
            //     {
            //         name: "Pinterest",
            //         url: "https://pinterest.com/linuscodes"
            //     },
            // ],
            projects: [],
            message: {
                text: '',
                success: false
            }
        }
    },
    computed: {
        ...mapGetters([ 'isAuthenticated', 'isLoading' ])
    },
    mounted() {
        this.fetchProjects(this.username);
    },
    methods: {
        async fetchProjects(username) {
            try {
                const response = await ProjectService.getAllProjectsForUserWithUsername(username)
                this.projects = response.projects
                this.message = {
                    text: response.message,
                    success: response.success
                }
            } catch(error) {
                this.message = {
                    text: error.message,
                    success: false
                }
            }
        }
    },
    components: {
        Toolbar,
        OverviewHeader,
        CardsGrid
    }
};
</script>
