<template>
    <header>
        <h1 class="full-name">{{ full_name }}</h1>
        <p class="bio">{{ bio }}</p>

        <ul class="socials">
            <li v-for="social in socials" :key="social.name">
                <a :href="social.url" class="btn primary">{{ social.name }}</a>
            </li>
        </ul>
    </header>
</template>

<script>
export default {
    name: 'OverviewHeader',
    props: [
        "full_name",
        "bio",
        "socials"
    ]
}
</script>

<style lang="scss" scoped>
header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 20%;
    gap: 80px;
    align-items: flex-end;
    margin-bottom: 100px;

    h1.full-name {
        font-size: 60px;

        @media screen and (max-width: 900px) {
            font-size: 40px;            
        }
    }

    .socials {
        justify-self: flex-end;
        list-style: none;

        li {
            display: inline-block;
            margin-top: 6px;

            &:not(:last-child) {
                margin-right: 6px;
            }
        }
    }

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
        gap: 20px 40px;

        .socials {
            grid-column: 2;
            justify-self: flex-start;
        }
    }

    @media screen and (max-width: 750px) {
        grid-template-columns: 1fr;

        .socials {
            grid-column: 1;
        }
    }
}
</style>