<template>
    <div class="cards-grid">
        <div class="card" v-for="project in projects" :key="project.name">
            <header>
                <div class="left">
                    <span class="project-name">{{ project.name }}</span>
                    <img src="@/assets/arrow.svg" class="arrow-icon" v-if="project.link">
                </div>
                <div class="right">
                    <span class="project-time">{{ project.time }}</span>
                </div>
            </header>

            <figure class="image-wrapper">
                <img :src="'/img/projects/' + project.image" :alt="project.name">
            </figure>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardsGrid",
    props: [
        "projects"
    ]
}
</script>

<style lang="scss" scoped>
.cards-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 20px;        
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }

    .card {
        cursor: pointer;

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            margin-bottom: 10px;

            span {
                letter-spacing: 0.3px;
            }

            .left {
                display: flex;
                align-items: center;

                .arrow-icon {
                    margin-left: 10px;
                    transform: translate(0, 2px);
                }
            }

            .right {
                .project-time {
                    color: #afafaf;
                }
            }
        }

        .image-wrapper {
            overflow: hidden;
            border-radius: 22px;
            height: 280px;
            display: flex;
            align-items: center;
            box-shadow: 0 10px 20px 3px rgba($black, 0.1);
            -webkit-mask-image: -webkit-radial-gradient(white, black);

            img {
                transition: 300ms ease;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &:hover {
            .image-wrapper {
                img {
                    transform: scale(1.02);
                }
            }
        }
    }
}
</style>